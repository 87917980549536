import React, { useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import CategoryContentQuery from '../../ContentEditor/CategoryContentQuery.gql';
import { useInView } from 'react-intersection-observer';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { BasicButton } from '../../ContentEditor/BasicButton';
import { useLocation } from 'react-router';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer'; // Se till att detta är rätt importväg
import { footerComponents } from '../../ContentEditor/ContentEditorComponents'; // Kontrollera rätt importväg

const MembersClubWrapper = styled('div')`
  background: ${theme.colors.gold};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  margin: auto;
  padding: 60px 40px;
  height: 100%;
  color: white;

  ${theme.below.lg} {
    padding: 40px 0;
    .usp-wrapper {
      padding: 0 20px;
      flex-direction: column;
    }

    h2 {
      font-size: 22px;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 16px;
  }

  .usp-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;

    li {
      padding-right: 20px;
      margin-left: 20px;
      list-style-type: disc;
    }
  }

  .button-wrapper {
    margin-top: 30px;
  }
`;

const MembersClubFooter = React.memo(() => (
  <MembersClubWrapper>
    <div>
      <h2>BLI MEDLEM I VÅR KUNDEKLUBB</h2>
      <ul className="usp-wrapper">
        <li>15% bonus på alle ordinære varer </li>
        <li>Fri frakt over 199,- </li>
        <li>Digitale kvitteringer </li>
        <li>Eksklusive og personlige tilbud </li>
      </ul>
    </div>
    <div className="button-wrapper">
      <BasicButton
        title={'Bli med nå'}
        text={'BLI MED NÅ'}
        theme={'WHITE'}
        link={'/login'}
      />
    </div>
  </MembersClubWrapper>
));

const Footer = () => {
  const categoryResult = useQuery(CategoryContentQuery, {
    variables: {
      id: 154
    }
  });

  const location = useLocation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const shouldShowMembersClubFooter = useCallback(() => {
    const path = location?.pathname;
    return !(
      path.includes('my-pages') ||
      path.includes('login') ||
      path.includes('signup')
    );
  }, [location]);

  return (
    <div ref={ref}>
      {shouldShowMembersClubFooter() && inView && <MembersClubFooter />}
      {categoryResult?.data?.category?.data?.items && (
        <ContentRenderer
          items={categoryResult?.data?.category?.data?.items}
          components={footerComponents}
        />
      )}
    </div>
  );
};

export default React.memo(Footer);
